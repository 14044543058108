import Link from 'next/link';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';

import { ImagingIcon } from '@/components/icons';

interface Props {
  header: string;
  caption: string;
  message: string;
}

export default function CustomError({ header, caption, message }: Props) {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <ImagingIcon className="h-12 fill-redtail-red" />
      <div className="pt-8 text-center">
        <div className="space-y-2">
          <p className="body-01 text-error">{header}</p>
          <h1 className="header-01 text-01">{caption}</h1>
          <p className="body-01 text-02">{message}</p>
        </div>
        <div className="justify-center flex mt-6 gap-1.5">
          <Link href="/home" className="button text-02 hover:underline">
            Go back home
          </Link>
          <ArrowNarrowRightIcon className="h-5 icon-01" />
        </div>
        <div className="justify-center flex mt-2 gap-1.5">
          <Link href="/logout" className="button text-02 hover:underline">
            Return to accounts
          </Link>
          <ArrowNarrowRightIcon className="h-5 icon-01" />
        </div>
      </div>
    </div>
  );
}
