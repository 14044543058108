import CustomError from '@/components/custom-error';

const customErrorProps = {
  header: '404 Error',
  caption: 'Page not found',
  message: 'Sorry, we couldn’t find the page you’re looking for.'
};

export default function Custom404() {
  return <CustomError {...customErrorProps} />;
}
